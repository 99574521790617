@import '../../styles/modules/_variables.scss';

.input-field {
  position: relative;
  margin-top: 23px;
  width: 100%;


  &.input-size-small {
    input {
      height: 38px;
      margin-top: 0;
      padding-left: 14px;
      padding-right: 14px;

      &::placeholder {
        font-size: 1.2rem;
      }
    }
  }



  &.input-no-label {
    margin-top: 0;
  }

  .field-label {
    font-size: 1.4rem;
    position: absolute;
    top: -24px;
    left: 0;
    color: $customGrey560;
    z-index: 1;
    display: block;
  }


  input {
    display: block;
    width: 100%;
    border-radius: 8px;
    outline: none;
    height: 48px;
    border: 1px solid $customGrey300;
    color: $black;
    padding-left: 20px;
    padding-right: 20px;
    /*input transition from antd*/
    transition: all $transitionDurationInput $transitionTimingInput;
    transition-property: all;
    transition-duration: $transitionDurationInput;
    transition-timing-function: $transitionTimingInput;
    transition-delay: $transitionDelayInput;

    &:hover {
      border-color: $customGreen500;
      box-shadow: 0 0 0 2px $customGreen520;
      border: 1px solid $customGreen500;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $customGrey400;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $customGrey400;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $customGrey400;
    }

    &::selection {
      background: $customGrey400 !important;
    }
  }
}
