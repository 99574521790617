@import '../../styles/modules/_variables.scss';

.right-panel {
  position: relative;

  .right-panel-top-actions {
    vertical-align: middle;
    display: flex;
    font-size: 1.6rem;
    color: $customGrey500;

    > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .action-btn {
      margin-bottom: 32px;
      cursor: pointer;

      a {
        color: inherit;
        display: flex;
        align-items: center;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  .right-panel-underhead-actions {
    display: flex;
    position: relative;

    @media #{$forPhoneOnly} {
      display: block;
    }

    .underhead-field-1, .projects-field-1, .record-book-field-1 {
      @media #{$forPhoneOnly} {
        margin-top: 70px;
      }
    }

    .underhead-field, .projects-field, .record-book-field {
      position: relative;
      margin-right: 10px;
      width: 20%;
      min-height: 64px;

      @media #{$forPhoneOnly} {
        width: 100%;
      }
    }

    .record-book-export-btn {
      margin-left: auto;
      padding: 0;

      @media #{$forPhoneOnly} {
        position: absolute;
        top: -60px;
        left: 0;
        margin-left: 0;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  .right-panel-header {
    position: relative;
    line-height: 1.4;
    justify-content: space-between;
    display: flex;

    /*@media #{$forPhonesAndTablets} {
      display: block;
    }*/

    @media #{$forPhonesAndTablets} {
      flex-direction: column;
    }

    .header-headline {
      max-width: 70%;
    }

    .right-panel-header-actions {
      max-width: 30%;
      display: flex;
      align-items: center;
      font-size: 1.6rem;

      @media #{$forPhonesAndTablets} {
        margin-top: 16px;
        margin-bottom: 8px;
        max-width: 80%;
      }

      &.has-notice {
        margin-left: auto;
        margin-right: 12px;
      }
    }

    .right-panel-header-notice {
      @media #{$forPhonesAndTablets} {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .right-panel-inner-actions {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    @media #{$forPhonesAndTablets} {
      flex-direction: column;
      align-items: flex-start;
    }

    .inner-actions-header {
      font-size: 2rem;
      font-weight: $fontWeightMedium;
    }

    .inner-actions-container {
      margin-left: auto;

      @media #{$forPhonesAndTablets} {
        margin-left: initial;
      }
      button, a {
        margin-left: 26px;

        @media #{$forPhonesAndTablets} {
          margin-top: 10px;
          display: block;
          margin-left: 0;
        }
      }
    }
  }
}