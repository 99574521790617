@import './_variables.scss';
@import './_material-design-palette.scss';

*, *:before, *:after {
  /*all elements border-boxed*/
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
}

html {
  font-size: $fontSizeHtml;
  line-height: $fontLineHeight;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: $black;
  background: $customGrey200;
  font-family: $fontMain;
  font-weight: $fontWeightRegular;
  font-size: $fontSizeMain;
  position: relative;
  transition: all $transitionDurationBase $transitionTimingBase;
  opacity: 1;
  /*overflow-x: hidden;*/

  &.disable-scroll {
    overflow: hidden;
    -ms-scroll-translation: none;
    -ms-overflow-style: none;
    -ms-scroll-chaining: none;
    -ms-touch-action: none;
    touch-action: none;
  }
}

#root {
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}


table {
  border-collapse: collapse;
}

th {
  font-weight: inherit;
}

dl, dd, dt, i {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  padding-left: 0.6em;
  margin-left: 0.7em;

}

p {
  margin: 0;
  padding-bottom: 0.8em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 1.8rem;
  border-left: 5px solid $customGrey300;
}

hr {
  border-color: $customGrey200;
}


a {
  color: $black;
  text-decoration: none;
  border-bottom: none;
  transition: $transitionDurationBase $transitionTimingBase;

  &.link-off, &.link-off:visited,
  &.link-off:active, &.link-off:focus {
    color: inherit;
    text-decoration: none;
    border-bottom: none;
    transition: none;
  }

  &.link-std, &.link-std:visited,
  &.link-std:active, &.link-std:focus {
    color: inherit;
    text-decoration: underline;
    border-bottom: none;
  }

  /* mouse over link */
  &:hover {
    color: $customGreen700;
  }

  &:active, &:focus, &:visited {
    color: $customGreen800;
  }
}

h1, .headline-1 {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeHeadline1;
}

h2, .headline-2 {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeHeadline2;
}

h3, .headline-3 {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeHeadline3;
}

h4, .headline-4 {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeHeadline4;
}

h5, .headline-5 {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeHeadline5;
}

h6, .headline-6 {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeHeadline6;

  @media #{$forPhoneOnly} {
    font-size: $fontSizeHeadline6 - 0.6rem;
  }
}


.thesis-text {
  font-weight: $fontWeightLight;
  font-size: $fontSizeThesis;
}

.accent-text {
  font-weight: $fontWeightRegular;
  font-size: $fontSizeAccent;
}

.header, .footer {
  position: relative;
}

.section, .page {
  display: block;
  position: relative;
}

$cLayoutMaxWidth: 1440px;
.container {
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  /*1920 - clean size*/
  max-width: $cLayoutMaxWidth;
  min-width: 300px;

  @media #{$forPhonesAndTablets} {
    padding-left: 16px;
    padding-right: 16px;
  }
}