@import '../../styles/modules/_variables.scss';

.ant-spin {
  color: inherit;
  font-size: inherit;
}

.loader-spinner-icon {
  color: inherit;
  font-size: inherit;
}
