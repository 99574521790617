@import '../../styles/modules/_variables.scss';

.ant-picker-dropdown {
  a.ant-picker-today-btn {
    color: $customGreen500;
  }

  .ant-picker-content {
    .ant-picker-cell {
      &.ant-picker-cell-selected .ant-picker-cell-inner{
        background: $customGreen500;
      }

      &.ant-picker-cell-today {
        .ant-picker-cell-inner:before {
          border: 1px solid $customGreen500;
        }
      }

      &:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
      &:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background: $customGreen500;
        color: $white;
      }
    }
  }

  .ant-picker-header {
    .ant-picker-header-view button:hover {
      color: $customGreen500;
    }

    > button:hover {
      color: $customGreen500;
    }
  }
}

.date-picker-field {
  position: relative;
  margin-top: 24px;
  width: 100%;

  .field-label {
    font-size: 1.4rem;
    position: absolute;
    top: -26px;
    left: 0;
    color: $customGrey560;
    z-index: 1;
    display: block;
  }

  .ant-picker {
    width: 100%;
    min-height: 48px;
    border-radius: 8px !important;
    border-color: $customGrey300;

    &:hover {
      border-color: $customGreen500;
      box-shadow: 0 0 0 2px $customGreen520;
    }

  }

  .ant-picker-focused {
    border-color: $customGreen500;
    box-shadow: 0 0 0 2px $customGreen520;
  }
}