.hide {
  display: none !important;
}

.show {
  display: block !important;
  &-inline {
    display: inline-block !important;
  }
}

.alert.alert-hidden {
  visibility: hidden;
}

.alert.alert-fixed {
  position: fixed;
  top: 70px;
  left: 30%;
  z-index: 10;
}

.no-results {
  text-align: center;
  padding: 20px;

  i {
    font-size: 30px;
  }
}

.text-transform-off {
  text-transform: none;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.anim-off {
  &:hover {
    transform: none;
  }
}

.shadow-off {
  box-shadow: none;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mt32 {
  margin-top: 32px;
}

.mb32 {
  margin-bottom: 32px;
}

.mt24 {
  margin-top: 24px;
}

.mb24 {
  margin-bottom: 24px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt12 {
  margin-top: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.pb20 {
  padding-bottom: 20px;
}

.pt20 {
  padding-top: 20px;
}

.container-float-fix {
}
