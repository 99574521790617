@import '../../styles/modules/_variables.scss';

.modal-container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.3);
  z-index: 16;
  visibility: hidden;
  transition:visibility 0s linear 0.1s,opacity 0.3s ease;

  &.opened {
    visibility:visible;
    opacity: 1;
    transition-delay: 0s;
    display: block;
  }

  .modal-clickable-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .modal {
    z-index: 20;
    margin: 10% auto;
    padding: 20px;
    width: auto;
    position: relative;
    background: $white;
    max-width: 80%;
    background: $white;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 36px;
    padding-bottom: 36px;
    border-radius: 16px;

    @media #{$forPhonesAndTablets} {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
      padding-bottom: 24px;
    }


    .modal-top {
      display: flex;
      align-items: center;
      z-index: 1;

      .modal-top-header {
        font-size: 3rem;
      }

      .modal-top-close {
        position: absolute;
        right: 36px;
        top: 44px;
        cursor: pointer;
        z-index: 2;

        @media #{$forPhonesAndTablets} {
          right: 24px;
          top: 32px;
        }

        .icon {
          color: $customGrey500;

          &:hover {
            color: $customGrey560;
          }
        }
      }
    }
  }
}
