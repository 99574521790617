@import '../../styles/modules/_variables.scss';

.smart-filter {
  display: flex;
  align-items: center;

  @media #{$forPhonesAndTablets} {
    display: block;
  }

  .smart-filter-name {
    font-size: 2rem;
    color: $black;
    font-weight: $fontWeightMedium;
    display: flex;
    align-items: center;
    padding-right: 20px;

    .name-text {

    }

    .name-date {
      font-size: 1.6rem;
      color: $customGrey410;
      margin-left: 20px;

      @media #{$forPhonesAndTablets} {
        margin-left: auto;
        margin-right: initial;
      }
    }
  }

  .smart-filter-actions {
    margin-left: auto;
    display: flex;
    justify-content: space-between;

    @media #{$forPhonesAndTablets} {
      margin-left: initial;
      margin-top: 20px;
      width: 100%;
    }

    .action-button {
      margin-left: 16px;

      @media #{$forPhonesAndTablets} {
        width: 33%;
      }

      .icon {
        font-size: 1.5rem;
      }
    }
  }
}

.smart-filter-copy-popover .ant-popover-inner-content {
  color: $customGreen500;
}
