@import '~antd/dist/antd.css';

@import '../styles/modules/_fonts.scss';
@import '../styles/modules/_icons.scss';
@import '../styles/service/_debug.scss';
@import '../styles/modules/_icons_sprite_generated.scss';
@import '../styles/modules/_base.scss';
@import '../styles/modules/_helpers.scss';

/*Buttons styles*/
.btn, a.btn {
  display: block;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  text-align: center;
  font-family: $fontMain;
  font-size: 1.6rem;
  font-weight: $fontWeightMedium;
  color: $white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  border: none;
  transition: none;
  box-shadow: $boxShadowBaseGrey;

  &:active, &:visited, &:focus {
    text-decoration: none;
    transition: none;
    border: none;
  }

  &.btn-green {
    background: $customGreen300;
    color: $white;
    background: linear-gradient(90deg, $customGreen300 0%, $customGreen500 100%);

    &:hover, &:focus {
      background: $customGreen700;
      background: linear-gradient(90deg,$customGreen400 0%, $customGreen700 100%);
    }

    &:active {
      background: $customGreen600;
    }

    &.disabled {
      background: $customGrey300;
      cursor: default;
      box-shadow: none;
    }
  }

  &.btn-white {
    background: $white;
    color: $customGreen500;

    &:hover, &:focus {
      background: $customGreen507;
    }

    &:active {
      background: $customGreen515;
    }

    &.disabled {
      background: $customGrey300;
      color: $white;
      cursor: default;
      box-shadow: none;
    }
  }

  /* buttons size presets */
  &.btn-xl {
    $btnLgWidth: 239px;
    $btnLgHeight: 52px;
    width: $btnLgWidth;
    height: $btnLgHeight;
    line-height: $btnLgHeight;
  }

  &.btn-lg {
    $btnLgWidth: 208px;
    $btnLgHeight: 40px;
    width: $btnLgWidth;
    height: $btnLgHeight;
    line-height: $btnLgHeight;
  }

  &.btn-md {
    $btnMdWidth: 176px;
    $btnMdHeight: 40px;
    width: $btnMdWidth;
    height: $btnMdHeight;
    line-height: $btnMdHeight;
  }

  &.btn-sm {
    $btnSmWidth: 150px;
    $btnSmHeight: 40px;
    width: $btnSmWidth;
    height: $btnSmHeight;
    line-height: $btnSmHeight;
  }

  /*BUTTON BUILDER*/
  /*button height*/
  &.btn-h-60 {
    $btn60Height: 60px;
    height: $btn60Height;
    line-height: $btn60Height;
  }

  &.btn-h-50 {
    $btn50Height: 50px;
    height: $btn50Height;
    line-height: $btn50Height;
  }

  &.btn-h-40 {
    $btn40Height: 40px;
    height: $btn40Height;
    line-height: $btn40Height;
  }

  /*button width*/
  &.btn-w-208 {
    $btn208Width: 208px;
    width: $btn208Width;
  }

  &.btn-w-176 {
    $btn176Width: 176px;
    width: $btn176Width;
  }

  &.btn-w-150 {
    $btn150Width: 150px;
    width: $btn150Width;
  }

  /* buttons with no fix width */
  &.btn-auto {
    width: auto;
    padding: 0.42em 3.3em;
    display: inline-block;
    overflow: initial;
    /*padding: 0.42em 50%;*/
  }
}

.btn-square, a.btn-square {
  display: inline-block;
  background: $customGreen500;
  color: $white;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 8px;
  box-shadow: $boxShadowButtonGreen2;

  &:hover, &:focus {
    background: $customGreen510;
    color: $customGreen500;
    box-shadow: none;
  }

  &.btn-lightgreen {
    background: $customGreen510;
    color: $customGreen500;
    box-shadow: none;

    &:hover, &:focus {
      background: $customGreen500;
      color: $white;
      box-shadow: $boxShadowButtonGreen2;
    }

    &:active {
      background: $customGreen600;
    }

    &.disabled {
      background: $customGrey300;
      cursor: default;
      box-shadow: none;
    }
  }

  &.size-md {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  &.size-lg {
    width: 56px;
    height: 56px;
    line-height: 56px;
  }
}

.btn-circle, button.btn-circle, a.btn-circle {
  display: inline-block;
  background: $customGrey200;
  outline: none;
  border: none;
  color: $customGrey500;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  border-radius: 100%;

  &.highlighted {
    background: $customGreen520;
    color: $customGreen500;
  }

  &.no-background {
    background: none;

    &:hover {
      background: none;
      color: $customGrey500;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.highlighted:hover, &:hover {
    background: $customGreen500;
    color: $white;
  }

  &.size-md {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }

  &.extra-spaced-mobile {
    @media #{$forPhonesAndTablets} {
      width: 100%;
      border-radius: 125px;
    }
  }
}

.btn-link {
  color: $customGreen500;
  font-size: 1.6rem;
  font-weight: $fontWeightBold;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
  vertical-align: middle;

  &:hover, &:active, &:focus, &:visited {
    color: $customGreen600;
  }

  &.disabled {
    color: $customGrey500;
    cursor: default;
  }

  > * {
    vertical-align: middle;
  }

  &.btn-link-grey {
    color: $customGrey600;
    font-size: 1.6rem;
    font-weight: $fontWeightMedium;

    &:hover {
      color: $customGrey560;
    }

    &:active, &:focus, &:visited {
      color: $customGrey600;
    }
  }

  &.btn-link-red {
    color: $customRed200;
    font-size: 1.6rem;
    font-weight: $fontWeightBold;

    &:hover {
      color: $customGrey560;
    }

    &:active, &:focus, &:visited {
      color: $customRed200;
    }
  }
}

/*Ant design styles edits*/
.ant-popconfirm {
  .ant-popover-message {
    .icon {
      position: absolute;
      top: 7.0005px;
      color: $customGreen500;
      font-size: 14px;
    }
  }

  .ant-popover-buttons {
    .ant-btn {
      border-radius: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .ant-btn-primary {
      background-color: $customGreen500;
      border-color: $customGreen500;
    }

    .ant-btn:hover, .ant-btn:focus {
      color: $customGreen500;
      background: #fff;
      border-color: $customGreen500;
    }
  }
}

.ant-pagination {

  .ant-pagination-item {
    border-radius: 4px;
    overflow: hidden;
    line-height: 20;
    transition: none;

    a {
      border-radius: 4px;
      transition: none;
      color: $customGrey600;
    }
  }

  .ant-pagination-item-active {
    border-color: $customGreen500;

    a {
      color: $customGreen500;
    }
  }

  .ant-pagination-prev button, .ant-pagination-next button {
    color: $customGrey600;
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: $customGreen500;
  }

  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: transparent;

    a {
      color: $white;
      background: $customGreen500;
    }
  }

}

.ant-empty {
  margin-left: auto;
  margin-right: auto;
}

.actions-manager-view {
  display: flex;
  margin-top: 32px;

  .manager-view-action {
    font-size: 1.6rem;

    &:first-child:after {
      content: '|';
      margin-left: 10px;
      margin-right: 10px;
      color: $customGrey300;

    }

    &.manager-view-link {
      color: $customGrey400;
    }

    &.manager-view-text {
      color: $customGrey600;
    }
  }
}

/*antd global rewrite checkbox styles component not used*/
.ant-checkbox-wrapper {
  &:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $customGreen500;
  }

  .ant-checkbox {
    &.ant-checkbox-checked .ant-checkbox-inner{
      background-color: $customGreen500;
      border-color: $customGreen500;
      color: $customGreen500 !important;
    }
  }
}

/*Notice component used in Card, Panels etc*/
.notice {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 10px;
  color: $customGreen500;
  background: $customGreen520;
  font-size: 2rem;
  line-height: 1.2;
  max-height: 38px;
  white-space: nowrap;

  @media #{$forPhoneOnly} {
    font-size: 1.2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 6px;
    max-height: 24px;
    line-height: 1.2;
  }

  @media #{$forTabletOnly} {

  }

  &.notice-top-right {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  &.notice-top-right-0 {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/*Separated pages styles*/
.page-header {
  margin-top: 40px;
  font-size: 3.6rem;
  line-height: 1.1 ;
  color: $customGreen500;

  @media #{$forPhonesAndTablets} {
    display: none;
  }

  [class^="headline"] {
    color: inherit;
  }
}

.page-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;

  @media #{$forPhonesAndTablets} {
    flex-direction: column;
  }

  .left-panel {
    max-width: 314px;

    @media #{$forPhonesAndTablets} {
      max-width: none;
    }
  }

  .right-panel {
    margin-left: 36px;
    width: 100%;
    max-width: 1005px;

    @media #{$forPhonesAndTablets} {
      margin-left: 0;
      max-width: 100%;
    }

    .right-panel-actions {

    }

    .right-panel-header {
      font-size: 2.6rem;
    }

    .right-panel-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media #{$forPhonesAndTablets} {
        flex-direction: column;
      }
    }
  }
}

.start-page {
  position: relative;
  display: block;
  z-index: 1;

  &.full-page {

  }

  .start-page-left-panel {
    display: block;
    position: relative;
    width: 100%;
    z-index: 5;
    padding-top: 10%;

    .start-page-subhead {
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: $fontWeightRegular;
      color: $customGrey410;
      z-index: 5;

      @media #{$forPhonesAndTablets} {
        color: $customGrey300;
      }
    }

    .start-page-slogan {
      position: relative;
      padding-top: 24px;
      line-height: 1.2;
      color: $black;
      width: 100%;
      max-width: 643px;
      min-width: 300px;
      font-size: 6.8rem;
      z-index: 3;
      /*text-shadow: 0px 20px 120px rgba(255, 255, 255, 1),0px -15px 120px rgba(255, 255, 255, 1),-20px 20px 120px rgba(255, 255, 255, 1);*/

      @media #{$forPhonesAndTablets} {
        color: $white;
        word-break: break-word;
        word-wrap: break-word;
        text-shadow: none;
      }

      @media #{$forTabletOnly} {
        width: 500px;
        font-size: 5rem;
      }

      @media #{$forPhoneOnly} {
        width: 250px;
        font-size: 3.2rem;
      }

      @media #{$forPhonesAndTablets} {}

      h1 {
        color: inherit;
        font-size: inherit;

        @media #{$forPhonesAndTablets} {
        }
      }
    }

    .btn {
      z-index: 3;
    }

    .start-page-copyright {
      max-width: 156px;
      margin-top: 22vh;
      opacity: 0.7;
      z-index: 3;

      @media #{$forPhonesAndTablets} {
        margin-top: 12vh;
      }

      @media #{$forDesktopOnly} {
        margin-top: 12vh;
      }
    }

    .start-page-left-panel-blur {
      z-index: -1;
      position: absolute;
      width: 50%;
      height: 100%;
      left: -20%;
      top: 0;
      border-radius: 100%;
      background: $white;
      opacity: 0.6;
      filter: blur(200px);
      /*box-shadow: 3px 8px 138px 71px rgba(255,255,255,0.82);*/

      @media #{$forPhonesAndTablets} {
        left: 0;
        display: none;
      }
    }

    .btn {
      margin-top: 60px;
      font-size: 2rem;
    }
  }

  .start-page-background {
    z-index: 1;
    /*position: absolute;*/
    /*width: 80%;
    height: 100%;*/
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: right center;
    background-color: $customGrey300;
    background-image: url('../images/common/smartphones-start-page.png');
    background-size: auto;
    width: 100%;
    height: 100vh;
  }

  .start-page-background-shading {
    display: none;
    background-color: rgba(0, 0, 0, 0.2);;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 100vh;

    @media #{$forPhonesAndTablets} {
      display: block;
    }
  }
}

.student-projects-modal.modal-container .modal {
  max-width: 680px;
}

.student-projects-files,
.student-projects-feedback {
  padding-top: 14px;

  .feedback-id {
    font-size: 1.4rem;
    color: $black;
    margin-bottom: 8px;
  }

  .feedback-project-name {
    font-size: 1.6rem;
    color: $black;
    margin-bottom: 8px;
  }

  .feedback-project-type {
    font-size: 14px;
    color: $customGrey410;
    margin-bottom: 8px;
  }

  .feedback-content {
    border-top: 1px solid $customGrey300;
    padding-top: 20px;
    margin-top: 12px;
    max-height: 300px;
    overflow-y: auto;

    .feedback-text {
      font-size: 1.4rem;
      color: $black;
    }

    .feedback-files-header {
      font-size: 1.8rem;
      padding-top: 20px;
      font-weight: $fontWeightMedium;
    }
  }

  .feedback-projects-files-list {
    padding: 0;
    margin: 0;
    list-style: none;

    .files-file-item {
      width: 100%;
      border-bottom: 1px solid $customGrey300;
      padding-bottom: 16px;
      padding-top: 16px;
      font-size: 1.4rem;

      &:last-child {
        border-bottom: none;
      }

      .files-file-item-link {
        display: flex;
        justify-content: space-between;

        &:hover .icon {
          color: inherit;
        }

        .icon {
          color: $customGrey500;
        }
      }
    }
  }
}


