@import '../../styles/modules/_variables.scss';

.login-modal.modal-container .modal {
  max-width: 398px;
}

.login-page-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;

  @media #{$forPhonesAndTablets} {
    margin-top: 12px;
  }

  .login-form-addition {
    &:after, &:before {
      display: none;
    }
  }
}

.login-form-container {
  margin: 0 auto;
  border-radius: 16px;
  position: relative;

  .login-form-header {
    font-size: 3rem;
  }

  .login-form {

    .login-form-input {
      margin-top: 40px;
    }

    .login-btn {
      margin-top: 34px;
    }
  }

  .login-form-addition {
    margin-top: 32px;
    border-top: 1px solid $customGrey300;
    padding-top: 24px;
    position: relative;
    display: flex;
    align-items: center;

    &:after, &:before {
      content: '';
      position: absolute;
      border-bottom: 1px solid $customGrey300;
      top: -1px;
      width: 36px;
      height: 1px;

      @media #{$forPhonesAndTablets} {
        width: 24px;
      }
    }

    &:after {
      right: -36px;

      @media #{$forPhonesAndTablets} {
        right: -24px;
      }
    }

    &:before {
      left: -36px;

      @media #{$forPhonesAndTablets} {
        left: -24px;
      }
    }

    .form-addition-text {
      color: $customGrey560;
      font-size: 1.4rem;
    }

    .form-addition-buttons {
      padding-left: 42px;

      button, a {
        font-size: 12px;
        font-weight: $fontWeightBold;
      }
    }
  }
}
