/* service 1 - on 0 off*/
$debugCssMode: 0 ;
$debugCssLine: 1;
$debugCssBackground: 1;

$fontRubik: Rubik, Helvetica, Arial, sans-serif;
$fontMain: $fontRubik;

/*
Light 300
Light 300 italic
Regular 400
Regular 400 italic
Medium 500
Medium 500 italic
Semi-bold 600
Semi-bold 600 italic
Bold 700
Bold 700 italic
Extra-bold 800
Extra-bold 800 italic
Black 900
Black 900 italic
*/
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: bold;

$fontSizeMain: 1.8rem;
$fontSizeSmall: 1.6rem;
$fontSizeSuperSmall: 1.4rem;
$fontSizeThesis: 2.4rem;
$fontSizeAccent: 2rem;

$fontSizeHeadline1: 6.8rem;
$fontSizeHeadline2: 5.2rem;
$fontSizeHeadline3: 4rem;
$fontSizeHeadline4: 3.6rem;
$fontSizeHeadline5: 3.2rem;
$fontSizeHeadline6: 2.6rem;
$fontSizeHeadline7: 1.8rem;
$fontSizeHeadline8: 1.4rem;

/*10px=62,5% root font-size*/
/* $fontSizeHtml: 62.5%; */
$fontSizeHtml: 10px;
$fontSizeHtml3kUp: 14px;
$fontLineHeight: 1.4;

/*------------TRANSITIONs, ANIMATIONS, VISUAL------------*/
$transitionDurationBase: 0.20s;
$transitionTimingBase: ease-out;

/*input transition from antd*/
$transitionDurationInput: 0.3s;
$transitionTimingInput: cubic-bezier(0.645, 0.045, 0.355, 1);
$transitionDelayInput: 0s;

/*0px 4px 30px rgba(193, 193, 193, 0.25)
0px 4px 30px 0px #C1C1C1 25%*/
$boxShadowBaseGrey: 0px 4px 30px rgba(193, 193, 193, 0.25);
/*0px 24px 32px rgba(128, 191, 68, 0.2)
0px 8px 24px 0px #80BF44 20%*/
$boxShadowButtonGreen1: 0px 24px 32px rgba(128, 191, 68, 0.2);
/*0px 5px 10px rgba(128, 191, 68, 0.3)
0px 5px 10px 0px #80BF44 30%*/
$boxShadowButtonGreen2: 0px 5px 10px rgba(128, 191, 68, 0.3);
/*0px 24px 32px 0px #80BF44 20%;
0px 24px 32px rgba(128, 191, 68, 0.2);*/
$boxShadowTileGreen: 0px 24px 32px rgba(128, 191, 68, 0.2);

/*Unused*/
$textShadowBase:   0px 0px 100px 0px rgba(50, 50, 50, 0.8);
$textShadowContrast: 1px 1px 2px #000, 0 0 1em black, 0 0 0.2em #000;
$textShadowStroke:  rgba(0, 0, 0, 1) 1px 1px 0, rgba(0, 0, 0, 1) -1px -1px 0,
rgba(0, 0, 0, 1) -1px 1px 0, rgba(0, 0, 0, 1) 1px -1px 0;

/* ----------COLOR VARIABLES------------*/
/*Main colors*/
$white: #ffffff; /* absolute white */
$black: #000000; /* absolute black */

$customGrey600: #666666;
/*background: #666666 60%;*/
$customGrey560: rgba(102, 102, 102, 0.6);
$customGrey530: rgba(255, 255, 255, 0.5);
$customGrey500: #949494;
$customGrey400: #BFBFBF;
$customGrey410: #C9C9C9;
$customGrey320: #E5E5E5;
/*#E3E3E3, 50%*/
$customGrey310: rgba(227, 227, 227, 0.5);;
$customGrey300: #E3E3E3;
$customGrey200: #F6F6F6;

/*Серые цвета на странице зачётки*/
$grey001: #C4C4C4;
$grey002: #C6C6C6;
$grey003: #BCBCBC;
$grey004: #BFBFBF;
/*global background*/
$grey005: #E5E5E5;
/* Значки под фото #666666 opacity 60%*/
$grey006: rgba(102, 102, 102, 0.6);
/*Бордер у инпутов на логин форме - disabled кнопка*/
$grey007: #E3E3E3;
/*напдипись на главной Все и даже больше*/
$grey008: #C9C9C9;
/*Заголовок на карточкой
Участие или призовое место на олимпиаде
а иногда он #000000*/
$grey009: #333333;
/*Название нового фильтра*/
$grey010: #C5C5C5;
/*Научная инновационная деятельность*/
$grey011: #9A9A9A;
$grey012: #CBCBCB;
/*Дашборд*/
/*тег под Количество иностранных студентов
C4C4C4 opacity 30%*/
$grey013: rgba(196, 196, 196, 0.3);
/*заголовки таблиц и инпуты в дашборде*/
$grey014: #BCBCBC;

$customGreen800: #5E8C32;
$customGreen700: #6FA63B;
$customGreen600: #79A64E;
/*Primary color*/
$customGreen500: #80BF44;
/* #80BF44 20% = #E6F2DA */
$customGreen520: rgba(128, 191, 68, 0.2);
/* 80BF44 15% = #DBE5D2*/
$customGreen515: rgba(128, 191, 68, 0.15);
/* 80BF44 10% = #F3F9ED */
$customGreen510: rgba(128, 191, 68, 0.1);
/* 80BF44 7% = #E5E9E1 */
$customGreen507: rgba(128, 191, 68, 0.07);
$customGreen400: #8BBF5A;
$customGreen300: #9AD364;
$customGreen200: #B9D1A2;

$customRed200: #D67474;

/* Colors third party*/
$colorLogoFb: #3b5998;
$colorLogoVk: #4d76a1;
$colorLogoTwitter: #00aced;
$colorLogoGoogle: #db4437;

/* -----------MEDIA QUERIES-----------*/
/* Media Queries group on gadgets*/
/*Screens width size
0-599 - phones
600-1199 - tablets
1200-1799 - desktop,
1800-2199 - 2k,
2200-3199 - 3k,
3200+ - 4k*/
$screen300: 300px; /* extra small */
$screen600: 600px; /* phones */
$screen900: 900px; /* tablets */
$screen1200: 1200px; /* desktops */
$screen1366: 1366px; /* laptops */
$screen1600: 1600px;
$screen1800: 1800px; /* 2K */
$screen2200: 2200px; /* 3k */
$screen3200: 3200px; /* 4K */
$screen4200: 4200px; /* 4K hight range and upper*/

/* RETINA FINDER need used for in OR condition*/
/* Safari, Safari IOS need prefix - autoprefixer do it*/
$retinaDetector1: "(min-resolution: 120dpi)";
/* IE 10, IE 11, Opera Mini */
$retinaDetector2: "(min-device-pixel-ratio: 1.25)";

/* -------EXTRA QUERIES---------- */
$forExtraSmallScreen: "(max-width: #{$screen300 - 1px})";

/* -------PHONES---------- */
/*$forPhoneUp - Phone Up it is all without media queries - FROM 0w TO infw*/
/* ALL Phones ONLY - FROM 0w TO 599w*/
$forPhoneOnly: "(max-width: #{$screen600 - 1px})";
/*ALL Phones ONLY Portair orientation - FROM 0w TO 599w + Portair*/
$forPhonePortairOnly: "(max-width: #{$screen600 - 1px}) and (orientation:portrait)";
/*ALL Phones ONLY Landscape orientation - FROM 0h TO 500h + Landscape*/
$forPhoneLandscapeOnly: "(max-width: #{$screen600 - 1px}) and (max-height: #{$screen600 - 100px}) and (orientation:landscape)";
/* $forPhoneLandscapeOnly: "(max-width: 599px) and (orientation:landscape)"; */

/* -----TABLETS--------- */
/* ALL Tablets ONLY Portair orientation AND OTHER Up larger devices size - FROM 600w TO infw*/
$forTabletPortraitUp: "(min-width: #{$screen600})"; /* it is = forTabletUp */
/* ALL tablet ONLY Portair orientation - FROM 600w TO 899w*/
$forTabletPortaitOnly: "(min-width: #{$screen600}) and (max-width: #{$screen900 - 1px})";
/* ALL Tablets ONLY Landscape orientation AND OTHER Up larger devices size - FROM 900w TO infw*/
$forTabletLandscapeUp: "(min-width: #{$screen900})";
/* ALL Tablets ONLY Landscape orientation - FROM 900w TO 1199w*/
$forTabletLandscapeOnly: "(min-width: #{$screen900}) and (max-width: #{$screen1200 - 1px})";
/* ALL Tablets ONLY - FROM 600w TO 1199w*/
$forTabletOnly: "(min-width: #{$screen600}) and (max-width: #{$screen1200 - 1px})";

/* -------TABLETS+PHONES COMBINES---------- */
/*ALL Phones AND ALL Tablets ONLY Portair orientation - FROM 0w TO 899w*/
$forPhonesAndTabletsPortair: "(max-width: #{$screen900 - 1px})";
/* ALL Phones AND ALL tablets (include landscape orientation) - FROM 0w TO 1199w*/
$forPhonesAndTablets: "(max-width: #{$screen1200 - 1px})";

/* -------DESKTOP---------- */
/*ALL Desktops AND OTHER Up larger devices size - FROM 1200w TO infw*/
$forDesktopUp: "(min-width: #{$screen1200})";
/* ALL Small Desktop ONLY - FROM 1200w TO 1365w*/
$forSmallDesktopOnly: "(min-width: #{$screen1200}) and (max-width: #{$screen1366 - 1px})";
/* ALL Small Desktop UP - TO 1365w*/
$forSmallDesktopAndPhonesAndTablets: "(max-width: #{$screen1366 - 1px})";
/* ALL Desktops ONLY - FROM 1200w TO 1799w*/
$forDesktopOnly: "(min-width: #{$screen1200}) and (max-width: #{$screen1800 - 1px})";
/*ALL Laptops AND OTHER Up larger devices size - FROM 1366w TO infw*/
$forLaptopUp: "(min-width: #{$screen1366})";
/* ALL Laptops ONLY - FROM 1366w TO 1799w*/
$forLaptopOnly: "(min-width: #{$screen1366}) and (max-width: #{$screen1800 - 1px})";
/* ALL Laptops ONLY - FROM 1601w TO 1799w*/
$from1366to1600: "(min-width: #{$screen1366}) and (max-width: #{$screen1600})";
/* ALL Laptops ONLY - FROM 1601w TO 1799w*/
$from1601to1799: "(min-width: #{$screen1600 + 1px}) and (max-width: #{$screen1800 - 1px})";
/* ALL Desktops-2K AND OTHER Up larger devices size - FROM 1800w TO infw */
$forDesktop2kUp: "(min-width: #{$screen1800})";
/* ALL Desktops-2K ONLY - FROM 1800w TO 2199w*/
$forDesktop2kOnly: "(min-width: #{$screen1800}) and (max-width: #{$screen2200 - 1px})";
/* ALL Desktops-3K AND OTHER Up larger devices size - FROM 2200w TO infw */
$forDesktop3kUp: "(min-width: #{$screen2200})";
/* ALL Desktops-3K ONLY - FROM 2200w TO 3199w*/
$forDesktop3kOnly: "(min-width: #{$screen2200}) and (max-width: #{$screen3200 - 1px})";
/* ALL Desktops-4K AND OTHER Up larger devices size - FROM 3200w TO infw */
$forDesktop4kUp: "(min-width: #{$screen3200})";
/* ALL Desktops-4K ONLY - FROM 3200w TO 4199w*/
$forDesktop4kOnly: "(min-width: #{$screen3200}) and (max-width: #{$screen4200 - 1px})";

/* 4K Upper larger devices size - FROM 3200w TO infw */
$forDesktop4kUpper: "(min-width: #{$screen4200})";

/* -------DESKTOP COMBINES---------- */
/* ALL Desktops AND ALL Desktops-2K - FROM 1200w TO 2199w*/
$forDesktopsAndDesktops2k: "(min-width: #{$screen1200}) and (max-width: #{$screen2200 - 1px})";
/* ALL Desktops-3k AND ALL Desktops-4K - FROM 2200w TO 4199w*/
$foDesktops3kAndDesktops4k: "(min-width: #{$screen2200}) and (max-width: #{$screen4200 - 1px})";
