@import '../../styles/modules/_variables.scss';

.card-manager-info {
  min-height: auto;
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;

  @media #{$forPhonesAndTablets} {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 12px;
    padding-left: 12px;
  }

  .manager-photo {
    width: 80px;
    min-width: 80px;
    vertical-align: top;

    .icon {
      text-align: center;
      width: 100%;
      height: 50px;
      color: $customGrey300;
    }

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .manager-info {
    margin-left: 20px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    .manager-name {
      font-weight: $fontWeightMedium;
      font-size: 2rem;
      color: $black;
    }

    .manager-education {
      font-size: 1.4rem;
      color: $customGrey400;
    }
  }
}