.icon-icon-academic-cap {
	font-size: calc((16/10)*1rem);
	width: calc((18/16)*1em);
}
.icon-icon-angle-down {
	font-size: calc((257.813/10)*1rem);
	width: calc((400/257.813)*1em);
}
.icon-icon-angle-up {
	font-size: calc((257.813/10)*1rem);
	width: calc((400/257.813)*1em);
}
.icon-icon-arrow-bold-left {
	font-size: calc((118.75/10)*1rem);
	width: calc((400/118.75)*1em);
}
.icon-icon-arrow-right {
	font-size: calc((11/10)*1rem);
	width: calc((27/11)*1em);
}
.icon-icon-ball {
	font-size: calc((17/10)*1rem);
	width: calc((16/17)*1em);
}
.icon-icon-chain {
	font-size: calc((18/10)*1rem);
	width: calc((18/18)*1em);
}
.icon-icon-check {
	font-size: calc((10/10)*1rem);
	width: calc((13/10)*1em);
}
.icon-icon-clip {
	font-size: calc((12/10)*1rem);
	width: calc((12/12)*1em);
}
.icon-icon-close {
	font-size: calc((682.667/10)*1rem);
	width: calc((682.667/682.667)*1em);
}
.icon-icon-copy {
	font-size: calc((12/10)*1rem);
	width: calc((12/12)*1em);
}
.icon-icon-document {
	font-size: calc((12/10)*1rem);
	width: calc((10/12)*1em);
}
.icon-icon-download {
	font-size: calc((15/10)*1rem);
	width: calc((14/15)*1em);
}
.icon-icon-external-link {
	font-size: calc((15/10)*1rem);
	width: calc((14/15)*1em);
}
.icon-icon-flask {
	font-size: calc((19/10)*1rem);
	width: calc((16/19)*1em);
}
.icon-icon-info {
	font-size: calc((24/10)*1rem);
	width: calc((24/24)*1em);
}
.icon-icon-logo-fb {
	font-size: calc((16/10)*1rem);
	width: calc((9/16)*1em);
}
.icon-icon-logo-profile-colored {
	font-size: calc((44/10)*1rem);
	width: calc((44/44)*1em);
}
.icon-icon-logo-tpu {
	font-size: calc((9/10)*1rem);
	width: calc((24/9)*1em);
}
.icon-icon-logo-vk {
	font-size: calc((10/10)*1rem);
	width: calc((16/10)*1em);
}
.icon-icon-menu {
	font-size: calc((24/10)*1rem);
	width: calc((24/24)*1em);
}
.icon-icon-palette {
	font-size: calc((17/10)*1rem);
	width: calc((17/17)*1em);
}
.icon-icon-pencil {
	font-size: calc((14/10)*1rem);
	width: calc((14/14)*1em);
}
.icon-icon-speaker {
	font-size: calc((20/10)*1rem);
	width: calc((17/20)*1em);
}
.icon-icon-square-check {
	font-size: calc((400/10)*1rem);
	width: calc((400/400)*1em);
}
.icon-icon-square-uncheck {
	font-size: calc((400/10)*1rem);
	width: calc((400/400)*1em);
}
.icon-icon-trash {
	font-size: calc((32/10)*1rem);
	width: calc((32/32)*1em);
}
.icon-icon-triangle-down {
	font-size: calc((8/10)*1rem);
	width: calc((10/8)*1em);
}
.icon-icon-triangle-up {
	font-size: calc((8/10)*1rem);
	width: calc((10/8)*1em);
}
.icon-icon-user {
	font-size: calc((16/10)*1rem);
	width: calc((16/16)*1em);
}
.icon-icon-user-2 {
	font-size: calc((20/10)*1rem);
	width: calc((20/20)*1em);
}
