.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  vertical-align: middle;

  &.grayscale {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    /* Firefox 10+ */
    filter: gray; /* IE6-9 */
    filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
    transition: all .6s ease; /* Fade to color for Chrome and Safari */
    backface-visibility: hidden; /* Fix for transition flickering */
  }
}