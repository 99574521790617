@import '../../styles/modules/_variables.scss';

.main {
  display: block;
  position: relative;

  @media #{$forPhonesAndTablets} {
    &.is-menu-opened {
      height: 90vh;
      overflow: hidden;
    }
  }
}
