@import '../../styles/modules/_variables.scss';

.tags {
  .tag {
    background: $customGrey200;
    display: inline-block;
    font-size: 1.4rem;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    color: $black;
    margin-right: 12px;
    margin-bottom: 12px;
    cursor: pointer;

    &.active {
      color: $customGreen500;
      background: $customGreen520;
    }


    input[type='radio'] {
      display: none;
    }

    label {
    }
  }
}