@import '../../styles/modules/_variables.scss';

.select-field {
  position: relative;
  margin-top: 24px;

  .field-label {
    font-size: 1.4rem;
    position: absolute;
    top: -24px;
    left: 0;
    color: $customGrey560;
    z-index: 1;
    display: block;
  }

  &.select-single {

  }

  &.select-multi {

  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $customGreen500;
    box-shadow: 0 0 0 2px $customGreen520;
  }

  .ant-select {
    width: 100%;
    background: none;
    color: $customGrey600;
    font-weight: $fontWeightMedium;

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $customGreen500;
      box-shadow: 0 0 0 2px $customGreen520;
    }

    .ant-select-selector {
      align-items: flex-start;
      padding-top: 10px;
      padding-left: 20px;
      min-height: 48px;
      border-radius: 8px !important;
      border-color: $customGrey300;

    }

    .ant-select-selection-search {
      /*FIX IT display none changing height of input*/
      /*display: none;*/

      input {
        height: 100% !important;
      }
    }

    .ant-select-selection-item {
      border: none !important;
      background: none !important;
      padding-left: 0;
    }
  }
}

/*selectbox antd*/
.ant-select-dropdown {
  .ant-select-item-option-selected {
    background: $customGreen510;
  }

  .ant-select-item-option-content {
    white-space: normal;
    overflow: auto;
  }

  .rc-virtual-list-scrollbar {
    /*display: block !important;*/
    width: 4px !important;

    .rc-virtual-list-scrollbar-thumb {
      background: $customGreen500 !important;
    }
  }

  &.option-multi {
    .ant-select-item.ant-select-item-option {
      &.ant-select-item-option-selected .ant-select-item-option-content:before  {
        border-color: $customGreen500;
      }

      .ant-select-item-option-state {
        position: absolute;
        left: 16px;
        top: 4px;
        color: $customGreen500;
        text-align: center;

        .icon {
          font-size: 12px;
          line-height: 1;
        }
      }

      .ant-select-item-option-content {
        margin-left: 32px;

        &:before {
          content: '';
          position: absolute;
          left: 12px;
          height: 20px;
          width: 20px;
          border: 1px solid $customGrey300;
          border-radius: 4px;
        }
      }
    }
  }

  &.option-single {
    .ant-select-item-option-state {
      display: none;
    }
    .ant-select-item-option-content:before {
      display: none;
    }
  }
}