@import '../../styles/modules/_variables.scss';



.switch-field {

  .ant-switch {
    min-width: 36px;
    height: 20px;
    line-height: 20px;
    background-color: $customGrey310;

    &.ant-switch-checked {
      background-color: $customGreen520;

      &:focus {
        box-shadow: 0 0 0 2px $customGreen200;
      }

      .ant-switch-handle::before {
        background-color: $customGreen500;
      }
    }

    .ant-switch-handle::before {
      background-color: $customGrey400;
    }


    .ant-switch-handle {
      width: 16px;
      height: 16px;
    }

    [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
      box-shadow: 0 0 0 0 $customGreen500;
    }
  }

}