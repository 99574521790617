@import '../../styles/modules/_variables.scss';

.header {
  width: 100%;
  height: 88px;
  background: $white;
  z-index: 15;
  padding-top: 24px;

  @media #{$forPhonesAndTablets} {
    height: 48px;
    padding-top: 8px;
    box-shadow: $boxShadowBaseGrey;
  }

  &.is-menu-opened {
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
    color: $white;

    /*.header-logo-container .header-logo-link .header-logo-text .header-logo-title {
      color: $white;
    }*/
    /*.header-logo-container .header-logo-link .header-logo-text .header-logo-subtitle {
      color: $customGrey530;
    }*/

    /*.header-menu-toggle .icon {
      color: $white;
    }*/
  }

  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-menu-toggle {
    display: none;
    position: absolute;

    .icon {
      color: $customGrey600;
      font-size: 18px;
    }

    @media #{$forPhonesAndTablets} {
      display: block;
    }
  }

  .header-logo-container {
    max-width: 400px;

    @media #{$forPhonesAndTablets} {
      margin: 0 auto;
    }

    .header-logo-link {
      display: flex;
      align-items: center;

      .header-logo-icon {
        width: 44px;
        height: 44px;
        font-size: 44px;
        line-height: 0.5;

        @media #{$forPhonesAndTablets} {
          width: 32px;
          height: 32px;
          font-size: 32px;
        }
      }

      .header-logo-text {
        padding-left: 25px;
        color: $black;

        .header-logo-title {
          font-weight: $fontWeightBold;
          font-size: 1.6rem;

          @media #{$forPhonesAndTablets} {
            font-size: 1.4rem;
          }
        }

        .header-logo-subtitle {
          font-weight: $fontWeightRegular;
          font-size: 1.2rem;
          color: $customGrey600;

          @media #{$forPhonesAndTablets} {
            display: none;
          }
        }
      }
    }
  }

  .header-top-menu {
    max-width: 400px;
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;

    @media #{$forPhonesAndTablets} {
      display: none;
    }

    .top-menu-average-points {
      padding-top: 5px;

      .points-text {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.4rem;
        margin-right: 16px;
      }

      .points-notice {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.8rem;
        line-height: 1;
        height: 40px;
      }
    }

    .top-menu-profile {
      position: relative;
      margin-left: 48px;

      &:hover .menu-profile-dropdown {
        display: block;
      }

      .btn-square {
        color: $white;

        &:hover {
          color: $customGreen500;
        }

        .icon.icon-user {
          font-size: 16px;
          /*FIX IT hack for center icon*/
          margin-left: -1px;
        }
      }

      a {
        color: $black;
        line-height: 1.85;
      }

      .menu-profile-dropdown {
        display: none;
        position: absolute;
        right: 0;
        top: 40px;
        background: $white;
        border-radius: 8px;

        .dropdown-list {
          list-style: none;
          margin: 0;
          padding: 0;
          min-width: 150px;
          width: auto;
          text-align: center;
          box-shadow: $boxShadowBaseGrey;

          .dropdown-item {
            display: block;
            width: 100%;
            cursor: pointer;
            font-size: 1.4rem;

            a {
              display: block;
              width: 100%;
              padding-bottom: 8px;
              padding-top: 8px;
              padding-left: 15px;
              padding-right: 15px;

              &:hover {
                color: $customGreen500;
              }
            }
          }
        }
      }
    }
  }
}


