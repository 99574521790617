@import '../../styles/modules/_variables.scss';

.card-round-shadow {
  display: block;
  position: relative;
  background: $white;
  box-shadow: $boxShadowBaseGrey;
  border-radius: 16px;
  font-size: 1.6rem;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  padding-left: 32px;
  /*padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;*/
  margin-bottom: 32px;
  min-height: 266px;
  width: 100%;
  transition: all $transitionDurationBase $transitionTimingBase;

  @media #{$forPhonesAndTablets} {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  &:hover {
    .card-link {
      .link-btn {
        border: 1px solid $customGreen500;
        display: block;
      }
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.has-hover:hover, &.has-hover:active, &.has-hover:focus {
    background: $customGreen500;
    color: $white;

    .card-top {
      .card-header, .card-notice {
        color: $white;
      }

      .card-notice {
        background: rgba(255, 255, 255, 0.2);
        color: $white;
      }
    }

    .card-content {
      color: $white;
    }
  }

  &.no-min-height {
    min-height: auto;
  }

  &.disabled, &.disabled:hover, &.disabled:active, &.disabled:focus {
    background: $white;
    color: $customGrey400;
    cursor: default;

    .card-top {
      .card-header, .card-notice {
        color: $customGrey400;
      }

      .card-notice {
        color: $customGrey400;
      }
    }

    .card-content .card-inner-text {
      color: $customGrey400;
    }

    .card-link {
      .link-btn {
        display: none;
      }
    }
  }

  &.collapsible {
    &.closed {
      .card-top {
        .card-header {
          padding-bottom: 0;
        }

        .card-header.separated {
          border-bottom: none;
          margin-bottom: 0;

          &:after, &:before {
            display: none;
          }
        }
      }

      .card-content {
        display: none;
      }
    }

    .card-top {
      cursor: pointer;
    }

    .card-top .card-notice {
      right: 54px;
    }
  }

  .card-top {

    .card-header {
      font-size: 2rem;
      font-weight: $fontWeightMedium;
      color: $black;
      line-height: 1.2;
      max-width: 70%;
      padding-bottom: 24px ;

      @media #{$forPhoneOnly} {
        font-size: 1.8rem;
      }

      &.separated {
        position: relative;
        max-width: 100%;
        width: 100%;
        font-size: 2.2rem;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $customGrey300;

        @media #{$forPhoneOnly} {
          font-size: 1.6rem;
          padding-bottom: 16px;
          margin-bottom: 16px;
        }

        @media #{$forPhonesAndTablets} {
        }

        &:after, &:before {
          content: '';
          position: absolute;
          border-bottom: 1px solid $customGrey300;
          bottom: -1px;
          width: 32px;
          height: 1px;

          @media #{$forPhonesAndTablets} {
            width: 24px;
          }
        }

        &:after {
          right: -32px;

          @media #{$forPhonesAndTablets} {
            right: -24px;
          }
        }

        &:before {
          left: -32px;

          @media #{$forPhonesAndTablets} {
            left: -24px;
          }
        }
      }
    }

    .card-notice {
      position: absolute;
      right: 24px;
      top: 24px;
      max-width: 130px;
      overflow: hidden;
    }

    .card-collapse-icon {
      position: absolute;
      right: 24px;
      top: 32px;
      color: $customGreen500;
      font-size: 1.6rem;

      /*.icon {
        width: 40px;
      }*/
      @media #{$forPhoneOnly} {
        font-size: 1.2rem;
        top: 24px;
      }
    }
  }

  .card-content {
    color: $customGrey600;

    p {
      line-height: 1.2;
    }
  }

  .card-link {
    min-height: 56px;

    .link-btn {
      display: none;
      font-size: 26px;
      color: $black;
      background: $white;
      position: absolute;
      border-radius: 12px;
      bottom: 24px;
      right: 24px;
      height: 56px;
      width: 56px;
      line-height: 56px;
      text-align: center;
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .card-link-mobile {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    @media #{$forPhonesAndTablets} {
      display: block;
    }
  }

  &.card-sm {
    max-width: 315px;
    width: 31%;

    @media #{$forPhonesAndTablets} {
      width: 100%;
      max-width: 100%;
    }
  }

  &.card-md {
    max-width: 432px;
    width: 43%;

    @media #{$forPhonesAndTablets} {
      width: 100%;
      max-width: 100%;
    }
  }

  &.card-lg {
    max-width: 546px;
    width: 54.4%;

    @media #{$forPhonesAndTablets} {
      width: 100%;
      max-width: 100%;
    }
  }

  &.card-xl {
    max-width: 662px;
    width: 65.7%;

    @media #{$forPhonesAndTablets} {
      width: 100%;
      max-width: 100%;
    }
  }

  &.card-half {
    /*max-width: 489px;*/
    max-width: 48.5%;

    @media #{$forPhonesAndTablets} {
      width: 100%;
      max-width: 100%;
    }
  }

  &.card-auto {
    max-width: 100%;
  }
}

.card-cols {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media #{$forPhonesAndTablets} {
    flex-direction: column;
  }

  .card-col {
    padding-left: 8px;
    padding-right: 8px;

    @media #{$forPhoneOnly} {
      padding-top: 10px;
    }

    @media #{$forPhonesAndTablets} {
      width: 100% !important;
    }
  }

  &.card-cols-2 {
    .card-col {
      /*padding-left: 8px;
      padding-right: 24px;*/
      width: 48%;
      padding-left: 8px;
      padding-right: 8px;

      /*&:last-child {
        padding-right: 8px;
      }*/
    }
  }

  &.card-cols-3 {
    .card-col {
      width: 30%;
    }
  }

  &.card-cols-4 {
    .card-col {
      width: 25%;
    }
  }

  .card-col-1 {
    width: 45%;
  }

  .card-col-2 {
    width: 55%;
  }
}

.card-inner-header {
  font-size: 2rem;
  font-weight: $fontWeightMedium;
  margin-bottom: 24px;
  margin-top: 32px;
  color: $black;

  @media #{$forPhoneOnly} {
    font-size: 1.4rem;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.card-table {
  .ant-table {
    table {
      @media #{$forPhoneOnly} {

      }
    }

    .ant-table-thead {
      th {
        color: $customGrey400;
        background: none;
        font-size: 1.4rem;
        padding-left: 0;
        padding-right: 16px;
        padding-bottom: 12px;
        padding-top: 12px;

        @media #{$forPhonesAndTablets} {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }


    .ant-table-tbody {
      td {
        color: $black;
        font-size: 1.4rem;
        padding-left: 0;
        padding-right: 16px;
        padding-bottom: 12px;
        padding-top: 12px;

        @media #{$forPhonesAndTablets} {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .table-like {
    width: 100%;
    position: relative;
    font-size: 1.4rem;
    line-height: 1.2;
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;

    .instructor-is-main {
      font-weight: $fontWeightMedium;
    }

    &.font-size-12, .font-size-12 {
      font-size: 1.2rem;
    }

    &.font-size-13, .font-size-13 {
      font-size: 1.3rem;
    }

    .table-header, .table-body {
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1 auto;
    }

    .table-header {
      width: 100%;
      color: $customGrey400;

      @media #{$forPhonesAndTablets} {
        display: none;
      }

      .table-row {
        padding-bottom: 12px;
      }
    }

    .table-body {
      width: 100%;
      color: $black;

      .table-data {
      }
    }

    .table-row {
      display: flex;
      flex-flow: row nowrap;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $customGrey300;

      @media #{$forPhoneOnly} {
        padding-top: 0;
        padding-bottom: 12px;
      }

      &:hover {
        /*background: $customGrey200;*/
      }

      &.row-desktop {
        display: flex;

        @media #{$forPhonesAndTablets} {
          display: none;
        }
      }

      &.row-mobile {
        display: none;
        border-bottom: none;

        @media #{$forPhonesAndTablets} {
          display: block;
        }

        &:hover {
          background: none;
        }
      }

      .table-data {
        flex-flow: row nowrap;
        flex-grow: 1;
        flex-basis: 0;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        /*-webkit-hyphens: auto;
        -webkit-hyphenate-limit-before: 3;
        -webkit-hyphenate-limit-after: 3;
        -webkit-hyphenate-limit-chars: 6 3 3;
        -webkit-hyphenate-limit-lines: 2;
        -webkit-hyphenate-limit-last: always;
        -webkit-hyphenate-limit-zone: 8%;

        -moz-hyphens: auto;
        -moz-hyphenate-limit-chars: 6 3 3;
        -moz-hyphenate-limit-lines: 2;
        -moz-hyphenate-limit-last: always;
        -moz-hyphenate-limit-zone: 8%;

        -ms-hyphens: auto;
        -ms-hyphenate-limit-chars: 6 3 3;
        -ms-hyphenate-limit-lines: 2;
        -ms-hyphenate-limit-last: always;
        -ms-hyphenate-limit-zone: 8%;

        hyphens: auto;
        hyphenate-limit-chars: 6 3 3;
        hyphenate-limit-lines: 2;
        hyphenate-limit-last: always;
        hyphenate-limit-zone: 8%;*/

        /*&:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 4;
        }
        &:nth-child(5) {
          order: 5;
        }
        &:nth-child(6) {
          order: 6;
        }
        &:nth-child(7) {
          order: 7;
        }
        &:nth-child(8) {
          order: 8;
        }
        &:nth-child(9) {
          order: 9;
        }
        &:nth-child(10) {
          order: 10;
        }*/

        &.data-wide-1-5 {
          flex-grow: 1.5;
        }

        &.data-wide-2 {
          flex-grow: 2;
        }

        &.data-wide-2-5 {
          flex-grow: 2.5;
        }

        &.data-wide-3 {
          flex-grow: 3;
        }

        &.data-wide-4 {
          flex-grow: 4;
        }
      }
    }


    /*Mobile view*/
    .table-row-spoiler {
      width: 100%;
      background: $customGrey200;
      border-radius: 8px;
      padding-left: 14px;
      padding-bottom: 14px;
      padding-top: 14px;
      padding-right: 14px;

      &.closed {
        .row-spoiler-content {
          display: none;
        }
      }

      &.opened {
        .row-spoiler-content {
          display: block;
        }
      }

      .row-spoiler-name {
        font-size: 1.4rem;
        display: flex;
        cursor: pointer;
        position: relative;

        .name-text {
          width: 90%;
        }

        .name-icon {
          color: $customGrey410;
          font-size: 1rem;
          text-align: right;
          width: 10%;
          padding-top: 2px;

          .icon {
            margin-left: auto;
          }
        }
      }

      .row-spoiler-content {
        font-size: 1.2rem;

        .data-name-spoiler {
          color: $customGrey560;
          margin-top: 16px;
        }

        .data-text-spoiler {
          padding-top: 4px;
        }
      }
    }

    /*Special case*/
    &.table-with-padding,  {
      .table-data {
        padding-right: 20px;

        @media #{$forPhonesAndTablets} {
          padding-right: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.card-inner-text {
  color: $black;

  &.color-text-grey {
    color: $customGrey560;
  }
}

.card-inner-chart {
  margin-top: 24px;
  /*HACK for svg chart center alignment */
  margin-left: -12px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.card-inner-bottom-actions {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;

  a, button, input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 20px;
  }
}

.card-read-field {
  width: 100%;
  font-size: 1.6rem;
  margin-bottom: 20px;

  .field-label {
    color: $customGrey560;
    margin-bottom: 10px;
  }

  .field-value {
    color: $black;
  }

  .field-actions {
    .field-btn {
      margin-right: 16px;
    }
  }
}

.search-users-list, .card-content .search-users-list {
  .search-users-item {
    .item-number {
      padding-right: 30px;
      font-size: 1.4rem;
      color: $customGrey400;
    }

    .search-user-photo {
      margin-right: 0;
      width: 100%;
      max-width: 60px;
      min-height: 60px;
      border-radius: 4px;
      overflow: hidden;

      @media #{$forPhoneOnly} {
        max-width: 48px;
        min-height: 48px;
      }

      img {
        max-width: 100%;
      }

      .icon {
        text-align: center;
        width: 100%;
        font-size: 4rem;
        color: $customGrey300;
      }
    }

    .search-user-short-info {
      margin-right: auto;
      margin-left: 22px;
      padding-right: 20px;

      .search-user-name {
        font-size: 1.6rem;

        @media #{$forPhoneOnly} {
          font-size: 1.4rem;
        }
      }

      .search-user-rang {
        font-size: 1.4rem;
        color: $customGrey400;

        @media #{$forPhoneOnly} {
          font-size: 1.2rem;
        }
      }

      .search-user-from {
        font-size: 1.4rem;

        @media #{$forPhoneOnly} {
          font-size: 1.2rem;
        }
      }
    }

    .search-user-points {
      white-space: nowrap;
      font-size: 1.4rem;
    }
  }
}

.card-unordered-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  color: $black;

  &.disabled {
    .unordered-item {
      opacity: 0.6;

      &.excluded-from-disable {
        opacity: 1;
      }
    }
  }

  .unordered-item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $customGrey400;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media #{$forPhonesAndTablets} {
    }

    @media #{$forPhoneOnly} {
      flex-wrap: wrap;
    }

    &.centered {
      align-items: center;
    }

    &:last-child {
      border-bottom: none;
    }

    .list-actions-left{
      margin-right: 10px;

      @media #{$forPhoneOnly} {
        margin-right: 0;
        width: 10%;
      }
    }

    .list-content {
      width: 80%;
      color: inherit;

      @media #{$forPhoneOnly} {
        width: 90%;
      }

      .content-list-events {
        margin-top: 20px;

        .event-item {
          display: flex;
          font-size: 1.4rem;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $customGrey300;
          cursor: auto;
          position: relative;

          &:last-child {
            border-bottom: none;
          }

          .ant-popover {
            display: none;
            width: 100%;

            @media #{$forPhoneOnly} {
              display: initial;
            }
          }

          .event-name {
            width: 70%;
            position: relative;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;

            @media #{$forPhoneOnly} {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 20px;
            }

            .event-info {
              display: none;
              color: $customGreen500;
              margin-right: 5px;

              @media #{$forPhoneOnly} {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
              }
            }

            .event-info-popover {

            }
          }

          .event-files {
            width: 15%;
            text-align: center;

            .file-link {
              display: block;
              width: 100%;

              .link-text {
                @media #{$forPhoneOnly} {
                  display: none;
                }
              }
              .link-icon {

              }
            }
          }

          .event-points {
            white-space: nowrap;
            width: 15%;
            color: $customGrey560;
          }
        }
      }
    }

    .list-actions-right {
      width: 20%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media #{$forPhoneOnly} {
        justify-content: center;
        margin-top: 10px;
        width: 100%;
      }

      .list-actions-notice {
        margin-left: auto;
        color: $customGrey560;
        font-size: 1.6rem;
        padding-right: 5%;
        white-space: nowrap;

        .notice-points {
          padding-top: 6px;
          padding-bottom: 7px;
        }
      }

      .list-actions-elements {
      }
    }
  }
}

.card-user-info {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;

  @media #{$forPhonesAndTablets} {
    min-height: auto;
    padding: 0;
  }

  .user-photo-info {

    @media #{$forPhonesAndTablets} {
      display: flex;
      justify-content: flex-start;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 12px;
      padding-left: 12px;
      box-shadow: $boxShadowBaseGrey;
      border-radius: 12px;
    }

    .user-photo {
      width: 100%;
      overflow: hidden;
      min-width: 240px;

      @media #{$forPhonesAndTablets} {
        min-width: auto;
        max-height: 120px;
        min-height: auto;
        max-width: 120px;
        border-radius: 8px;
      }

      img {
        width: 100%;
      }

      .icon {
        text-align: center;
        width: 100%;
        padding: 30px;
        height: 100%;
        color: $customGrey300;
      }
    }

    .user-info {
      @media #{$forPhonesAndTablets} {
        margin-left: 18px;
      }

      .user-info-name {
        display: none;
        font-weight: $fontWeightMedium;
        font-size: 2rem;

        @media #{$forPhonesAndTablets} {
          display: block;
        }
      }

      .user-info-location {
        font-weight: $fontWeightMedium;
        color: $customGrey600;
        font-size: 1.6rem;
        margin-top: 20px;
      }

      .user-info-education {
        font-size: 1.4rem;
        margin-top: 10px;
        color: $customGrey400;
        font-weight: $fontWeightRegular;
      }
    }

  }

  .user-points {
    margin-top: 18px;

    @media #{$forPhonesAndTablets} {
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }

    &.is-opened {
      flex-direction: column-reverse;

      .user-points-trigger {
        transform: rotate(180deg);
      }
    }

    .user-points-trigger {
      display: none;
      cursor: pointer;
      text-align: center;
      color: $customGreen500;

      @media #{$forPhonesAndTablets} {
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .user-all-points {

      @media #{$forPhonesAndTablets} {
        &.hidden {
          display: none;
        }

        padding-top: 21px;
        padding-bottom: 21px;
      }

      .points-wrapper {
        display: inline-block;
        width: 33%;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;


        @media #{$forPhonesAndTablets} {
          padding-left: 10%;
        }

        &.disabled-points {
          color: $customGrey300;

          .points-icon {
            color: inherit;
          }
        }
      }

      .points-icon {
        font-size: 18px;
        color: $customGrey560;
      }

      .points-value {
        padding-left: 14px;
      }
    }
  }
}

.card-activities-menu {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;

  @media #{$forPhonesAndTablets} {
    background: $white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 10;
    display: none;
  }

  &.is-opened {
    display: block;
  }

  .activities-menu-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    font-weight: $fontWeightMedium;
    color: $customGrey600;
    font-size: 1.6rem;
    width: 100%;

    @media #{$forPhonesAndTablets} {
      padding-left: 20px;
      padding-right: 20px;
      padding-top:  66px;
      padding-bottom: 16px;
      box-shadow: $boxShadowBaseGrey;
      border-radius: 12px;
    }

    .activities-menu-item {
      width: 100%;

      a {
        color: inherit;
        padding-top: 14px;
        padding-bottom: 12px;
        padding-left: 24px;
        padding-right: 24px;
        display: block;
        width: 100%;

        @media #{$forPhonesAndTablets} {
          padding-top: 12px;
          padding-bottom: 12px;
        }

        &:hover {
          color: $customGreen500;
        }

        &.active {
          color: $customGreen500;
          background: $customGreen520;
        }
      }
    }
  }
}

.activities-menu-list.activities-menu-list-mobile {
  display: none;
  padding-top: 24px;
  box-shadow: none;

  @media #{$forPhonesAndTablets} {
    display: block;
  }

}

.card-block-share {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  padding-left: 24px;
  color: $customGrey600;

  @media #{$forPhonesAndTablets} {
    display: none;
  }


  .card-block-share-action {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
  }
}

.qr-container {
  position: relative;

  @media #{$forPhonesAndTablets} {
    background: $white;
    position: fixed;
    padding-top: 128px;
    padding-right: 36px;
    padding-left: 36px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  .share-qr {
    width: 100%;
    margin-top: 25px;

    canvas {
      display: block;
      margin: 0 auto;
    }
  }

  .share-actions {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    @media #{$forPhonesAndTablets} {
      display: block;
      margin-top: 44px;
    }

    .btn, .btn-link {
      @media #{$forPhonesAndTablets} {
        margin-bottom: 32px;
        display: block;
        width: 100%;
      }
    }
  }
}
